.orders-details-actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0px 30px 0px;
    
    h4{
        width: 50%;
        font-weight: 700;
        font-size: 1.7rem;
    }
    .orders-actions{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50%;

        button{
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: auto;
            margin: 0px 15px;
            font-family: 'Roboto', sans-serif;
        }
    }
}
.btn-primary:hover{
    background-color: #07504d !important;
}
