.home {
    background-color: #f5f4f4;
    height: 100%;
}

.HomeContent h3 {
    padding: 0px 20px;
    margin: 30px 0px 5px 0px;
    font-size: 30px;
    color:#191A15;
    font-weight: 700;
}
.HomeContent p {
    padding: 0px 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color:#191A15;
    font-weight: 400;
}

.QuickActions {
    display: flex;
    justify-content: space-between;
    padding: 30px;

    .quick-actions{
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: space-between;
    }
    .quick-action {
        background: #FFF;
        color: #025C5A;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 15px;
        margin: 25px;
        height: 80%;
        display: flex;
        align-items: center;

        h4{
            font-weight: 700;
            font-size: 1.7rem;
        }
    } 
    .quick-action:hover {
        background: #f0eeee;
    }
}