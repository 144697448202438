Footer {
  background-color:#D9D9D9; /* #F3E7E7*/;
  color: #421831;
  padding:10px 0 10px;
  font-family: Outfit, sans-serif;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    justify-content: space-around;
    padding: 20px;
    background-color: #F1F0F0 /* #F3E7E7*/;
    color: #421831;
}

.footer-section-about {
    flex: 1;
    padding: 0 20px;
    
}

.facebook-container svg {
    color: #421831;
    font-size: 30px;
}
    
.footer-section ul {
    list-style: none;
    flex-direction: column;
    padding: 0 20px;
    margin: 20px;
}

.footer-section ul li a {
    color: #421831;
    text-decoration: none;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}
.footer-section ul li a:hover {
    color: #793356;
}

.contact li {
    list-style: none;
    padding: 0 20px;
    margin: 20px;
    display: flex; /* Make the li a flex container */
    align-items: center; /* Center the items vertically */
}

hr {
    border: 0;
    border-top: 2px solid #270017;
    margin: 20px;
}

.footer-bottom {
    color: #421831;
    text-align: flex-start;
    margin: 20px;
}

/*RESPONSIVE*/
@media screen and (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        font-size: 15px;
    }
    .mobile-hide {
        display: none;
    }
    .logo-text{
        margin: 10px 0px;
    }
    .footer-section-about, .contact {
        width: 100%;
    }
    .footer-section-about p {
        font-size: 15px !important;
    }
    .contact {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 10px 0px;
        padding: 0px 20px;
    }
    .contact li {
        padding: 0;
        margin: 10px 0px;
        font-size: 15px;
    }
    .footer-bottom {
        text-align: center;
    }
}