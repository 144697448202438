.btn-outline-primary{
    margin: 0px 5px 18px 0px !important;
    border-radius: 5px !important;
    background-color: #FFF !important;
    border-color: #4c1f3b !important;
    color: #4c1f3b !important;
}
.btn-outline-primary:hover{
    background-color: #4c1f3b !important;
    border-color: #4c1f3b !important;
    color: #FFF !important;
}