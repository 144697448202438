.settings-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 30px;
    width: 100%;

    h2, h4{
        font-weight: 600;
        margin: 0;
    }
    h5{
        font-weight: 700;
        margin: 0;
    }
    hr {
        margin: 20px 0;
    }
    p{
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        margin: 15px 0;
    }

    .row{
        display: flex !important;
        align-items: center !important;

        form{
            width: 70%;
            margin: 10px 0;

            input{
                margin: 0 !important;
            }
        }
    
        .dropdown button{
            background-color: #602548 !important;
            border-color: #41112d !important;
            width: 100%;
            margin: 10px 0;
            padding: 10px;
        }
        button:hover{
            background-color: #41112d !important;
        }
    
        a:hover{
            background-color: rgb(216, 216, 216) !important;
    
        }
        a:active{
            background-color: #602548 !important;
        }
    }

    .profile{
        display: flex;
        align-items: center;
        margin: 10px 0;

        .bi-person-gear{
            width: 4rem;
            height: 4rem;
            margin-right: 15px;
            fill:#602548 !important;
        }
    }

    .table-responsive, .pagination {
        width: 100%;
        margin: 20px 0;
    }
    .pagination {
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
    }
    .pagination:hover {
        cursor: pointer;
    }
    .page-link{
        color: #4c1f3b;
        border: 1px solid #4c1f3b;
    }
    .page-link:hover{
        background-color: #4c1f3b !important;
        border-color: #4c1f3b !important;
        border-color: #4c1f3b !important; 
        color: #fff !important;       
    }
    .page-item.active .page-link {
        z-index: 1;
        color: #fff;
        background-color: #4c1f3b;
        border-color: #4c1f3b;
    }
}