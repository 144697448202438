.password-forget{
    padding: 2rem;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;

    section{
        width: 50%;
        padding: 5rem;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 25px;
    }
    section>div{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        svg{
            color: #4e1e3b;
            border-radius: 30%;
            width: 20%;
            height: 20%;
            padding: 1rem;
        }
    }
    p{
        font-family:'Roboto', sans-serif;
        font-size: 1rem;
    }
    a{
        display: flex;
        justify-content: center;
        text-decoration: none;

        &:hover{
            text-decoration: underline;
            color: #014241;
        }
        p{
            margin: 1rem;
        }
        svg{
            width: 25px;
            height: 25px;
            padding: 3px;
        }    
    }
}

/*Responsive design*/

@media screen and (max-width: 768px){
    .password-forget{
        padding: 1rem;

        section{
            width: 100%;
            padding: 1rem;
        }
        section>div{
            svg{
                width: 30%;
                height: 30%;
            }
        }
        h2{
            font-size: 1.5rem;
        }
        p{
            font-size: 0.9rem;
            margin: 5px;
        }
        form{
            padding: 10px;
        }

    }
}