Footer{
  padding: 0px;
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .footer-content {
    background-color:  #D9D9D9;
  }
  
  .footer-content p {
    color: #421831;
    font-size: 18px;
  }
  
  @media (max-width: 600px) {
    .footer-content p {
      font-size: 12px;
    }
    .footer-content {
      display: flex;
      align-items: center;
    }
  }