.maestros-details{
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;

    h2{
        font-weight: 600;
        margin: 0;
    }
    hr {
        margin: 20px 0;
    }
    p{
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        margin: 15px 0;
    }
}

.maestros-details-actions{
    display: flex;
    margin: 10px 0px 30px 0px;
    
    h4{
        width: 85%;
        font-weight: 700;
        font-size: 1.7rem;
    }
    button{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 15%;
        margin: 0px 15px;
        font-family: 'Roboto', sans-serif;
    }
}
.btn-primary:hover{
    background-color: #07504d !important;
}

.first-col, .second-col{
    font-family: 'Roboto', sans-serif;
    width: 50%;
}

.maestros-contactos{
    margin: 0;

    h4{
        font-weight: 700;
        font-size: 1.5rem;
    }

    .nombre-contactos{
        margin: 35px 0 0 0;
    }
}

/*SPINNER*/

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh; 
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    p{
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
  }

/*modal*/

.modal-title svg{
    color: #e0c31b;
    width: 2rem;
    height: 2rem;
}
