.logistics{
    color: #00504E;
    font-family: Outfit, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    padding-left: 30px;
    margin: 40px 0px 0px 0px; 
}

.logistics-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logistics-sevices-first, .logistics-sevices-second {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.logisticsIcons-container {
    width: 25%; 
    height: auto; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin: 20px 70px;
    padding: 20px;
}

.logisticsIcons-container img {
    max-width: 100%; 
    max-height: 100%;
    width: auto; 
    height: auto; 
}

.logistics-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;
    background: #EFEFEF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.logistics-text p {
    color: #232323;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    padding: 20px;
}

.logistics-sevices-first {
    display: flex;
    flex-direction: row-reverse;
}

/*responsive*/
@media screen and (max-width: 768px) {
    .logistics {
        font-size: 30px;
        margin: 30px 0px 20px 0px;
    }
    .logistics-sevices-first, .logistics-sevices-second {
        flex-direction: column-reverse;
    }
    .logisticsIcons-container{
        width: 50%;
    }
    .logisticsIcons-container {
        margin: 20px 0px;
    }
    .logistics-text {
        width: 80%;
    }
    .logistics-text p {
        font-size: 16px;
    }
}