.empaque{
    margin: 10px 0;

    h4 {
        font-weight: 700;
        font-size: 1.5rem;
    }

    .btn-primary{
        margin: 15px 0;
        background-color: #602548 !important;
        border-color: #602548 !important;
        color: white !important;
        font-weight: 400;
        font-size: 1rem;
        font-family: 'Roboto', sans-serif;
        
        &:hover{
            background-color: #3d182e !important;
            border-color: #3d182e !important;
            color: white !important;
        }
    }
}

.input-group{
    .btn-primary{
        width: 25%;
        margin: 0px 5px 18px 0px !important;
        border-radius: 5px !important;
        background-color: #602548 !important;
        border: #602548;

        &:hover{
            background-color: #3d182e !important;
            border-color: #3d182e !important;
        }
    }
}