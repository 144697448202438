  .accordion-flush{
    --bs-accordion-active-bg: #d1cfcfb4  !important;
    --bs-accordion-active-color: #3d142d !important;
    background-color: var(--bs-accordion-active-bg);
    color: var(--bs-accordion-active-color);
  }
/*GENERAL*/
.sidebar{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 2rem;
  margin: 0.5rem 0;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border-right: 1px solid rgba(0, 0, 0, 0.10);
}
/*ICON*/
.sidebar svg{
  padding-right: 10px;
  width: auto;
  height: 1.4rem;
  fill: #602548;
}
.bi-person-circle{
  height:4rem !important;
  fill: rgb(75, 75, 75) !important;
}

/*PROFILE INFO*/
.profile-info{
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.profile-info div{
  width: 90%;
}
.profile-info h5, .profile-info p{
  padding: 5px 0 0 0;
  margin: 0;
  color: rgb(71, 71, 71);
}
.profile-info h5{
  font-size: 1.2rem;
  font-weight: 600;
}
.profile-info p{
  font-size: 0.9rem;
  font-weight: 400;
}

/*MENU*/
.profile-container, .menu-container, .settings{
  width: 100%;
}
.menu-container{
  flex-direction: column;
  margin-top: 1rem;
}

nav hr{
  width: 90%;
  justify-content: center;
}

.accordion-flush>.accordion-item:first-child {
  border-top: 0;
  border-bottom: 0;
}

.custom-link, .nav-link, .accordion-item button{
  color: #602548;
}
.accordion-body ul{
  padding: 0px 15px;
  margin: 0;
}  
.accordion-body ul a{
  color: #602548;
  text-decoration: none !important;
}
.accordion-body ul a:hover, .custom-link:hover, .nav-link:hover, .accordion-body ul a:focus, .custom-link:focus, .nav-link:focus{
  color: #9e4179 !important;
}
.accordion-item:not(:first-of-type)  {
  border-bottom: 0;
  border-top: 0;
}
.nav-link{
  padding: 8px 20px;
}
.accordion-button:hover{
  background-color: #d1cfcfb4;
  border-radius: 10px !important;
}
.accordion-button:focus,
.accordion-button:active {
  background-color: #d1cfcfb4 !important; 
  color: #3d142d !important; 
  border-radius: 10px !important;
  box-shadow: none !important; 
  border-color: transparent !important; 
}

/*SETTINGS*/
.settings p{
  margin-bottom: 0.5rem;
}