.user-details {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 30px;
    width: 100%;

    h2, h4{
        font-weight: 600;
        margin: 0;
    }
    h5{
        font-weight: 700;
        margin: 0;
    }
    hr {
        margin: 20px 0;
    }
    p{
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        margin: 15px 0;
    }

    .profile{
        display: flex;
        align-items: center;
        margin: 10px 0;

        .bi-person{
            width: 4rem;
            height: 4rem;
            margin-right: 15px;
            fill:#602548 !important;
        }

        button{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            margin: 0 10px !important;
            padding: 6px 12px !important;
            font-family: 'Roboto', sans-serif;
        }
    }
}