.logout-button {
  color: red;
  background-color: #fff;
  padding: 0px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  display: flex;
  justify-content: flex-start;
}
.logout-button:hover {
  color: rgb(145, 16, 16);
}
.bi-box-arrow-left{
    fill:red !important;
}
.bi-box-arrow-left:hover{
  fill:rgb(145, 16, 16) !important;
}