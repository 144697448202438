.unauthorized{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;

    .col{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h4{
        font-size: 1.2rem;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 1rem;
    }

    .bi-robot{
        width: 60%;
        height: 60%;

    }
   
}