/*Header*/
header {
    position: relative;
    top: 0; left: 0; right: 0;
    background-color: #FFFFFF; 
    padding: .5rem 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 1000;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
}

.header-logo {
  position: static;
  width: 100%; 
  height: 65px;
}

.signin-button {
    background-color: #8E4D74;
    color: white;
    padding: 10px 25px; 
    border-radius: 76px;
    text-decoration: none;
    border: none;
    font-size: 18px;
    font-family: 'Outfit', sans-serif;
    margin: 4px 2px;
    cursor: pointer;
}

.signin-button:hover {
    background-color: #6E2D4D;
    text-decoration: none;
}

.sign-in:hover {
  text-decoration: none !important;
} 

.navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end; 
    list-style: none; 
    font-family: 'Outfit', sans-serif;
}

.navbar a {
    text-decoration: none;
    color: #000000;
    font-size: 18px;
    margin: 0 10px; 
}

.navbar a:hover {
    text-decoration: underline;
    text-underline-offset: 5px; /* Adjust the position of the underline */
    text-decoration-thickness: 2px; 
    text-decoration-color: #421831;
}

.menu{
  display: none;
  justify-content: flex-end;
  padding: 0px 15px;
}

@media (max-width: 900px) { 
  .header-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .header-logo {
    width: 100%; 
    height: 45px; 
    justify-self: center; /* Center the logo */
  }

  .menu {
      display: flex;
      cursor: pointer;
  }

  nav {
      flex-direction: column;
      justify-content: center;
      width: 100%;
      border-radius: 10px;
  }

  nav a {
      display: none;
      flex-direction: column;
      flex-wrap: wrap;
      width: auto;
      padding: 5px;
      margin-bottom: 0.25rem;
  }

  nav .sign-in {
    width: 100%;
  }

  nav.open a {
      display: flex;
      justify-content: center;
  }

}