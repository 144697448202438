/* Container */
.title-container {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #F3E7E7;;
  color: #421831;
  text-align: center;
}

.txt-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
}
.txt-title h2 {
  font-size: 50px;
  font-family: Outfit, sans-serif;
  font-style: normal;
}

.about-img-container {
    width: 50%;
    display: flex;
    justify-content: center;
}

.about {
    display: flex;
    align-items: center;
    margin: 0px 40px;
    width: 40%;
    height: auto;
}

.quienes-somos h4, .quienes-somos h6, .quienes-somos p {
    margin: 5px 0px;

}

/*Responsive*/
@media screen and (max-width: 768px) {
    .about{
        width: 60%;
    }
    .txt-title h2 {
        font-size: 30px;
        padding-left: 30px;
    }
    .about-img-container {
        width: 50%;
    }
}

/*Quienes Somos*/

.aboutUs-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #FFFFFF;
    text-align: flex-start;
    margin: 45px 0px;
}

.quienes-somos {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-self: center;
    text-align: justify;
    width: 50%;
    height: auto;
    background-color: #FFFFFF;
    padding: 0px 60px 0px 30px;
}

.map-container{
    display: flex;
    justify-content:center;
    width: 50%;
    height: auto;
}

.map {
    display: flex;
    justify-content: center;
    width: 70%;
    border-radius: 173px;
}

.quienes-somos h6 {
    font-size: 20px;
    margin: 0px 0px 10px 0px;
}

.quienes-somos h4{
    font-size: 28px;
}

.quienes-somos p {
    font-size: 18px;
}

/*Responsive*/
@media screen and (max-width: 768px) {
    .aboutUs-container {
        flex-direction: column-reverse;
        margin: 20px 0px;
    }
    .quienes-somos {
        width: 80%;
        padding: 30px;
    }
    .map-container {
        width: 100%;
    }
    .map {
        width: 80%;
    }
    .quienes-somos h4 {
        font-size: 20px;
    }
    .quienes-somos h6 {
        font-size: 16px;
    }
    .quienes-somos p {
        font-size: 14px;
    }
}