.maestros-form{
    height: auto;
    padding: 25px 40px;

    h2{
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 0px;
    }
    h5{
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 20px;
    }
    form{
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }
    .btn-primary{
        width: 30% !important;
        margin: 10px;
        padding: 10px;
        font-size: 1rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        background-color: #602548 !important;
        border-color: #602548 !important;
    }
    .btn-primary:hover{
        background-color: #4c1f3b !important;
        border-color: #4c1f3b !important;
    }
}

.col{
    padding: 0 20px;
}

hr{
    border: 1.2px solid #000000;
    width: 100%;
    margin: 20px 0;
}

.form-label{
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
}

.form-control, .form-select, .form-check-input{
    margin: 0px 5px 18px 0px !important;
    border: #858585dc 1px solid !important;
    padding: 10px !important;
    border-radius: 5px !important;  
    font-family: 'Roboto', sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
}
.form-select{
    option{
        font-family: 'Roboto', sans-serif;
        font-style: normal;
    }
}
.invalid-feedback {
    margin-bottom: 10px !important;
}

.mb-3{
    display: flex;
    flex-direction: column;
}