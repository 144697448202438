.maestros-list {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;

    h2{
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 0px;
    }

    hr{
        border: 1.2px solid #000000;
        width: 100%;
        margin: 20px 0;
    }

    .table-responsive, .pagination {
        width: 100%;
        margin: 20px 0;

        input{
            margin: 0 !important;
        }
    }
    .pagination {
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
    }
    .pagination:hover {
        cursor: pointer;
    }
    .page-link{
        color: #4c1f3b;
        border: 1px solid #4c1f3b;
    }
    .page-link:hover{
        background-color: #4c1f3b !important;
        border-color: #4c1f3b !important;
        border-color: #4c1f3b !important; 
        color: #fff !important;       
    }
    .page-item.active .page-link {
        z-index: 1;
        color: #fff;
        background-color: #4c1f3b;
        border-color: #4c1f3b;
    }
}

.table-bordered{
    border: 1px solid #96999c !important;
}
th, tr {
    font-family: 'Roboto', sans-serif;
}