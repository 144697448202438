.clients{
    color: #421831;
    font-family: Outfit, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    padding-left: 30px;
    margin: 40px 0px 0px 0px; 
}

.clients-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.clients-degraded {
    border-radius: 40px;
    background-color: #f3eaea;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    height: 60%;
    padding: 30px;
    margin: 40px;
}

.clients-degraded p, .clients-degraded li {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; 
}

.business {
    color: #421831;
    font-size: 35px;
    font-weight: 700;
    font-family: Outfit, sans-serif;
    font-style: normal;
    padding-left: 30px;
}

.clients-icons-container {
    background-color: #F3E7E7;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 40px 0px;
    padding: 20px;
}

.clients-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #421831;
    font-family: Outfit, sans-serif;
    font-weight: 600;
    font-size: 20px;
}

.clients-icons img {
    width: 5rem;
    height: 5rem;
    margin: 10px;
}

/*Responsive*/
@media screen and (max-width: 768px) {
    .clients{
        font-size: 30px;
    }
    .clients-degraded {
        width: 80%;
        margin: 20px
    }
    .clients-degraded p, .clients-degraded li{
        font-size: 15px;
    }
    .business{
        font-size: 25px;
    }
    .clients-icons-container{
        margin: 15px 0px;
        padding: 15px;
    }
    .clients-icons{
        font-size: 10px;
    }
    .clients-icons img {
        width: 2.5rem;
        height: 2.5rem;
        margin: 5px;
    }
}