.prov{
    color: #00504E;
    font-family: Outfit, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    padding-left: 30px;
}

.suppliers-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 60px 0px;
}

.mainIcon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;
}

.mainIcons {
    width: 65%;
    height: auto;
}

.mainIconsMobile {
    display: none;
}

.suppliers-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50%;
    height: auto;
    padding-right: 60px;
}

.suppliers-text h4 {
    color: #00504E;
    font-size: 40px;
    font-weight: 700;
    font-family: Outfit, sans-serif;
    font-style: normal;
}

.suppliers-services {
    color: #232323;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 200% */
}

.suppliers-second-services {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin: 20px 0px;
    font-family: Outfit, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}

.services-container-first, .services-container-second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 192px;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
} 

.services-container-first {
    background-color: #8BAD79;
    color:  #013231;
}

.services-container-second {
    background-color: #EFEFEF;
    color:  #013231;
}

/*responsive*/
@media screen and (max-width: 768px) {
    .prov{
        font-size: 32px;
        margin: 20px 0px;
        padding-left: 25px;
    }
    .suppliers-row{
        flex-direction: column;
        margin: 35px 0px;
    }
    .suppliers-text{
        width: 85%;
        justify-content: center;
        align-items: center;
        padding: 15px;
        margin: 15px;
    }
    .suppliers-text h4{
        font-size: 32px;
    }
    .suppliers-services{
        font-size: 15px;
    }

    .mainIcon-container{
        width: 90%;
    }
    .mainIcons {
        display: none;
    }
    .mainIconsMobile{
        display: block;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
    }

    .suppliers-second-services{
        flex-direction: column;

    }
    .services-container-first, .services-container-second{
        width: 70%;
        display: flex;
        flex-direction: row;
        padding: 0px 15px;
    }
    .services-container-first p, .services-container-second p{
        font-size: 18px;
    }
    .suppliersIcons {
        width: 4.5rem;
        height: 4.5rem;
        margin-right: 15px;
    }

}