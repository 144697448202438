.aboutOffice {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    background-color: #EFEFEF;
}

.flag-container {
    display: flex;
    align-items: center;
    width: 45%;
    height: auto;
    padding: 20px 10px;
    gap: 20px;
    margin: 20px 0px;
}

.flag {
    width: 120px;
    height: 120px;
}

.nuestras-oficinas{
    font-size: 75px;
    display: flex;
    align-items: center;
    justify-content: center !important;
}
.flag-container-txt {
    margin: 0px 30px;
}
.flag-container-txt h4 {
    font-size: 25px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    margin: 0px;
}
.flag-container-txt p{
    margin: 5px 0px;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-family: 400;
}

/*responsive*/

@media screen and (max-width: 800px) {
    .aboutOffice {
        width: 100%;
    }
    .nuestras-oficinas{
        font-size: 55px;
        justify-content: flex-start !important;
        height: auto;
    }
    .flag-container {
        width: 100%;
        align-items: center;
        padding: 20px 30px;
    }
    .flag-container-txt p{
        font-size: 16px;
    }
    .flag-container-txt {
        margin: 20px 0px;
    }
    .flag {
        width: 110px;
        height: 110px;
    }
}