.password-reset{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: #f1f1f1;

    section{
        width: 50%;
        padding: 5rem;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border-radius: 25px;

        h2{
            font-weight: 600;
        }
    }
}

/*responsive*/
@media screen and (max-width: 768px){
    .password-reset section{
        padding: 2rem;
    }
}