.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Outfit', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.col-md-9, .col-12 {
  min-height: 100vh; /* or another minimum height as needed */
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.col-md-3, .col-md-9{
  height: auto;
  background-color: #f5f4f4;
}

@media (max-width: 768px) { /* Para pantallas pequeñas */
  body {
    transform: scale(0.8);
    transform-origin: top left;
    width: 125%;
  }
}


