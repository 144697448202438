header {
    position: relative; 
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
}

.Appheader-logo {
  position: static;
  width: 100%; 
  height: 65px; 
}

@media (max-width: 700px) {
  .Appheader-logo {
    width: 100%; 
    height: 45px; 
  }
  .header {
    display: flex;
    justify-self: flex-start;
    padding: 5px 8px;
  }
  header .logo{
    padding: 5px 8px;
  }
}