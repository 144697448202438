/*Hero Section*/
.hero{
    width: 100%;
    height: 670px;
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    justify-content: center, space-between;
    gap: 20px; /* This will add the same space between all items */
    margin-left: 20px; /* Add some space to the left */
    width: 50%;
    margin-left: 75px;
}
.contentMobile {
    display: none;
}

.text1 {
    justify-content: center;
    align-items: center;
    font-size: 50px;
    height: 100%;
    font-weight: 800;
    line-height: 55px;
    color: #232323;
    font-family: Outfit, sans-serif;
    font-style: normal;
    margin: 0;
}

.vector {
    width: 95%;
    height: 100%;
    display: flex;
}

.text2 {
    justify-content: center;
    align-items: center;
    color: #232323;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    width: 100%;
}

a {
    text-decoration: none;
}

.content .productos{
    display: flex;
    justify-content: center;
    background-color: #148683; 
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 76px;
    padding: 15px 20px;
    gap: 10px;
    font-size: 18px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}

.content .productos:hover {
    background-color: #0F5A4C;
}

/*Degraded container Section*/
.degraded-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #F3E7E7 0%, #FFF 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 55px;
    height: auto;
    width: 90%;
    padding: 30px 0;
}
.degraded-container h3{
    font-size: 40px;
    margin: 20px 0;
}
.degraded-container p{
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: #383838;
    font-Weight: 400;
}
.degraded-container h4{
    font-family: 'Outfit', sans-serif;
    color: #421831;
    font-weight: 700;
    font-size: 30px;
    margin: 20px 0px 10px 0;
}

.explora{
    display: flex;
    padding: 10px 20px 10px 20px;
}
.explora div {
    width: 50%;
    padding: 20px;
}

.servicios-div{
    justify-content: flex-start;
}
.NuestrosServicios-container{
    margin: 0px 40px;
}
.NuestrosServicios {
    background-color: #8E4D74;
    color: white;
    border: none;
    border-radius: 76px;
    cursor: pointer;
    padding: 12px 20px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
}
.NuestrosServicios:hover {
    background-color: #6E2D4D;
}
  
/*responsive*/
@media (max-width: 768px) {
    .hero{
        height: 180px;
        width: 390px;
    }
    .content{
        display: none;
    }
    .contentMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 15px;
        padding: 20px;
        background-color: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .text1{
        font-size: 22px;
        line-height: 25px;
    }
    .text2{
        font-size: 14px;
        line-height: 20px;
    }
    .contentMobile .productos{
        display: flex;
        justify-content: center;
        background-color: #148683; 
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 76px;
        padding: 10px 15px;
        gap: 10px;
        font-size: 15px;
        font-weight: bold;
        font-family: "Roboto", sans-serif;
    }

    /*Degraded container Section*/
    .explora{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .explora div{
        width: 90%;
        padding: 5px;
    }
    .degraded-container{
        flex-direction: column;
        width: 90%;
        padding: 20px 0;
    }
    .degraded-container h3{
        font-size: 24px;
    }
    .degraded-container p{
        font-size: 14px;
    }
    .degraded-container h4{
        font-size: 20px;
    }

    .servicios-div{
        justify-content: center;
    }   
    .NuestrosServicios{
        font-size: 17px;
    }
}