/*container*/ 
.title-container {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #F3E7E7;;
    color: #421831;
    text-align: center;
  }
  
  .txt-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
  }
  .txt-title h2 {
    font-size: 50px;
    font-weight: 700;
    font-family: Outfit, sans-serif;
    font-style: normal;
  }

.products {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 30%;
    height: auto;
}

/*description*/
.category-description {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    margin: 20px 30px;
}
.category-description h3{
    font-size: 50px;
    margin: 10px 0;
}
.category-description p{
    font-size: 20px;
    margin: 10px 0;
}

/*products*/
.products-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 40px;
}

.product {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: #8BAD79;
    border-radius: 30px;
    margin: 20px;
    width: 500px;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.product img {
    display: flex;
    justify-content: flex-start;
    width: 50%;
    height: auto;
    border-radius: 30px;
}
.product h3{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 50%;
    color: #FFF;
    font-size: 25px;
    font-weight: 600;
    margin: 0;
    padding: 0px 35px;
}

.product:hover {
    background-color: #469579;
}

/*categoqries*/
.category-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.volver{
    display: flex;
    justify-content: flex-start;
    text-decoration: none; 
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    color: black; 
    margin: 20px 0px;
}

/*responsive*/
@media screen and (max-width: 768px) {
    .txt-title h2 {
        font-size: 35px;
    }
    .products {
        width: 60%;
    }
    .services-title h2 {
        font-size: 30px;
        padding-left: 30px;
    }
    .category-description h3{
        font-size: 30px;
    }
    .category-description p{
        font-size: 18px;
    }
    .products-container {
        gap: 20px;
        margin: 15px 0px;
    }
    .product h3 {
        font-size: 20px;
    }
}