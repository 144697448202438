/* Equipo */

.conoce-nuestro-equipo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: 90px;
    font-size: 35px;
    padding-left:30px;
}

.aboutTeam {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px 30px;
    height: auto;
}

.card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    margin: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}

.aboutTeam-container {
    box-sizing: border-box;
    display: flex;
    width: 600px;
    height: 50px;
    background-color: #421831;
    justify-content: center;
    align-content: center;
    padding: 10px 0px; 
}

.aboutTeam-container h3 {
    display: flex;
    align-self: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 25px;
    color: #FFFFFF;
    margin: 0px;
}

.aboutTeam-info {
    background-color: #EFEFEF;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 600px;
    height: auto;
    padding: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}

.aboutTeam-info h5 {
    font-size: 20px;
    font-weight: 600;
    color: #232323;
    margin: 10px;
}

.aboutTeam-info p {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #232323;
    margin: 10px;
}

/*Responsive*/

@media screen and (max-width: 768px) {
    .aboutTeam-container, .aboutTeam-info {
        width: 350px;
    }
    .aboutTeam-container h3{
        font-size: 20px;
    }
    .aboutTeam-info h5 {
        font-size: 18px;
    }
    .aboutTeam-info p {
        font-size: 15px;
    }
}