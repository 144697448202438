.inicio-sesion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  height: 100%;
}

section .signin-gradient, section .signin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 45px 30px;
}

.signin-container h4{
    margin: 0px;
    font-size: 24px;

}

/*Conentenedor Gradiente*/
section .signin-gradient {
    border-radius: 20px;
    background: linear-gradient(180deg, #87AD79 0%, #8BAD79 0.01%, #84AB79 5%, #469579 50%, #017C79 100%);
    color: white;
    width: 65%;
    min-height: 100vh;
    margin: 0px;
    display: flex;
    align-items: flex-start;   
}

.signin-gradient h5 {
    width: 80%;
    color: #FFF;
    font-family: 'Outfit', sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    margin: 0px 20px;
}

/*Inicio de Sesión*/
section .signin-container {
    display: flex;
    align-items: center;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 40%;
    height: auto;
    margin: 30px;
    gap: 30px;
}

.logo-signin {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.logo-signin img {
    width: 104px;
    height: 112px;
}

button .email-container, .password-container {
    box-sizing: border-box;
}

.email-container, .password-container {
    display: flex;
    align-items: center;
    width: 80%;
    border-radius: 20px;
    border: 1.622px solid #a5a4a4;
    background: #FFF;
    padding: 10px 20px;
}

.email-container .username, .password-container .password {
    border: none;
    outline: none;
    font-family: 'Outfit', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: #313131;
    width: 100%;
    padding: 0px;
    margin: 0px;
}

.btn-login {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: none;
    background: #8E4D74;
    color: #FFF;
    cursor: pointer;
    font-family: 'Outfit', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 10px;
    text-decoration: none;
}
.btn-login:hover {
    background: #6E2D4D;
}
.btn-login:disabled{
    background: #8E4D74;
    cursor: default;
}

.remember-forgot, .forgot-password {
    color: #333;
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.remember-forgot:hover, .signin:hover {
    text-decoration: underline;
    text-underline-offset: 4px; /* Adjust the position of the underline */
    text-decoration-color: #333;
}

/*RESPONSIVE*/
@media (max-width: 768px) {
    section .mobile-hide {
      display: none;
    }
    section .signin-mobile{
        width: 95%;
    }
    .email, .password {
        font-size: 15px;
    }
    .inicio-sesion {
        min-height: 80vh;
    }
  }

