/* Container */
.contact-us {
    width: 100%;
    height: auto;
}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    height: auto;
    background-color: #F3E7E7;
    color: #421831;
    padding: 30px;;
  }
  
  .contact-title {
    font-size: 40px;
    font-weight: 600;
    font-family: Outfit, sans-serif;
    font-style: normal;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

/*CONTACT FORM*/
.contact-row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 70vh;
}

.contact-image, .form-container {
  width: 50%;
  height: auto;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contacto {
  width: 100%;
  height: auto;
}

.contact-form, .contact-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container{
  Form {
    width: 80%;
    height: auto;
    padding: 30px;
    border-radius: 25px;
    background: #F8F7F7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);   
  }
  
  input[type="text"], #message, input[type="email"] {
    width: 100%;
    height: auto;
    padding: 10px 30px 10px 15px !important;
    border-radius: 10px;
    background: #FFF;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
  }
  
  textarea{
    resize: none;
  }
}

/*RESPONSIVE*/
@media screen and (max-width: 768px) {
  .contact-title {
    font-size: 20px;
  }

  .contact-row {
    flex-direction: column;
    width: auto;
  }

  .contact-image, .form-container {
    width: 95%;
    padding: 40px 0px;
  }
  Form{
    padding: 30px 15px;
  }

  .txt-title {
    width: 100%;
  }
  .txt-title h2 {
    font-size: 35px;
  }

  .contact-form {
    width: 80%;
  }

  .contact-inputs {
    width: 80%;
  }

  .contact-button {
    width: 80%;
  }
}
