.tracking-container{
    .nav-item{
        a{
            color: #531d3e;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
        }
    }

    .nav-pills  .nav-link.active{
        background-color: #6b2550f6;
        color: #fff !important;
    }

    .tab-content {
        background-color: #fdfdfd;
        padding: 2.5rem;
        border-radius: 15px;
        box-shadow: 0px 0px 10px 0px #0000001a;
    }

    .tracking-details{
        p{
            font-size: 15px;
        }

        .btn-secondary{
            width: auto !important;
            margin: 10px 5px 0px 0px !important;
        }
        .btn-secondary:hover{
            background-color: rgb(2, 92, 90) !important;
        }
    }

    .delay-warning{
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 5px;
        margin: 10px 0px;
    }
    .delay-warning span{
        margin-left: 5px;
        font-size: 16px;
    }

    .form-control, .form-select{
        padding: 5px !important;
    }

    .form-check{
        padding: 0px !important;
        margin: 5px 5px 5px 0px !important;
    }
    .form-check-input{
        padding: 9px !important;
        margin: 2.5px !important;
    }
}