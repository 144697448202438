/* Container */
.services-img-container {
    width: 50%;
    display: flex;
    justify-content: center;
}

.services {
    display: flex;
    align-items: center;
    margin: 0px 40px;
    width: 45%;
    height: auto;
}

/*responsive*/
@media screen and (max-width: 768px) {
  .services-img-container{
    width: 50%;
  }
  .services{
    width: 80%;
    height: auto;
  }

  .txt-title h2 {
    font-size: 35px;
  }
}
